import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import Layout from "components/Layout";
import { useTheme } from "components/Theming";
import Container from "components/Container";
import { rhythm } from "../lib/typography";

const Hero = () => {
  const theme = useTheme();
  return (
    <section
      css={css`
        color: ${theme.colors.white};
        width: 100%;
        background: ${theme.colors.headerBg};
        padding: 20px 0 30px 0;
        display: flex;
      `}
    >
      <Container
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <p
          css={css`
            color: ${theme.colors.white};
            position: relative;
            z-index: 5;
            font-weight: 300;
            line-height: 1.5;
            margin: 0;
            //max-width: ${rhythm(15)};
          `}
        >
          I sometimes find it mind-boggling that someone would ever get bored in
          this world. There are so many interesting things to explore, so many
          skills to learn. But if I had to list only 3 areas that interest me
          most, I would say: <strong>code</strong>, <strong>sound</strong>, and{" "}
          <strong>ideas</strong>.
        </p>
      </Container>
    </section>
  );
};

export default function About({ data: { site } }) {
  return (
    <Layout site={site}>
      <Hero />
      <Container
        css={css`
          padding-bottom: 0;
        `}
      >
        <h3>Code</h3>
        <p>Programming</p>
        <h3>Sound</h3>
        <h3>Ideas</h3>
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`;
